module.exports = {
  // App Info
  appstore_link: "https://kintimer.app.link/bN2pFH7Nb4", // Enter App Store URL.
  //playstore_link: "https://imedadel.me", // Enter Google Play Store URL.
  google_analytics_ID: "UA-75096825-12", // Enter Google Analytics ID or ""
  //presskit_download_link: "https://imedadel.me", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://kintimer.app", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "KinTimer: Addiction Counter",
  app_price: "Free",
  app_description:
    "A counter for quitting bad habits and improving your motivation",
  app_keywords: ["Counter", "Timer", "Quit", "Habits"],
  download_label: "Download",
  // Personal Info
  your_name: "",
  your_link: "",
  your_city: "",
  email_address: "helloheydays@gmail.com",
  linkedin_username: null,
  facebook_username: null,
  //instagram_username: "ed_adel",
  twitter_username: "kintimer_appp",
  //github_username: "ImedAdel",
  youtube_username: null,

  // Features List
  features: [
    {
      title: "Easy to Use",
      description: "Intuitive and easy to use! See everything at a glance.",
      fontawesome_icon_name: "sliders-h"
    },
    {
      title: "Ranking System",
      description: "Your ranking increases as your day count climbs higher",
      fontawesome_icon_name: "medal"
    },
    {
      title: "Log Feature",
      description:
        "Your past attempts are recorded so you can see how long you lasted!",
      fontawesome_icon_name: "history"
    }
  ],
  header_background: "rgba(0, 0, 0, 0.1)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#1d63ea",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666"
};
